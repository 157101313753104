<template>
    <div>
        <h3>Price Management</h3>
        <a-tabs default-active-key="1" size="large">

			<!-- Units Tab -->
			<a-tab-pane key="1" tab="Units">
				<template v-if="Object.values(storedUnits).length">
                    <div class="mt-4 dF aC" style="justify-content:space-between">
                        <div class="dF aC">
                            <a-checkbox :indeterminate="indeterminateUnits" :checked="checkAllUnits"  @change="onchangeAllUnits" class="mr-3" />
                            <h5 style="margin-bottom:0">Units</h5>
                            <div style="color:#9EA0A5" class="ml-3">{{`${Object.values(storedUnits).length} in total`}}</div>
                        </div>
                        <div class="dF aC">
                            <div @click="$p >= 40 ? $store.commit('OPEN_HIGHRISE_BUMP', {type:'units',ids:selectedUnits, cb:onBump}) : $message.error('You do not have permission to adjust the price')" v-if="selectedUnits.length != 0" class="dF aC px-3 py-1" style="border-radius:3px; background-color:#F79425; cursor:pointer; color:#FFF; height:33.72px">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>
                                <div class="ml-3">Adjust Price</div>
                            </div>
                        </div>
                    </div>
                    <div class="dF mt-4" style="gap: 2rem; flex-direction: column;">
                        <div v-for="(unit, index) in Object.values(storedUnits)" :key="unit.id">
                            <div class="display-card py-3 px-3">
                                <div class="dF aC" style="justify-content:space-between">
									<div id="left" v-if="showScrollArrows" @click="scrollLeft(unit.id)"><a-icon type="left" /></div>
									<div :id="unit.id" :class="index === 0 ? 'price-content price-div' : 'price-content'">
	                                    <div class="dF">
	                                        <a-checkbox class="pr-3" @change="onChangeUnit(unit.id)" :checked="selectedUnits.includes(unit.id)" />
	                                        <div class="pr-3" style="min-width:60px; width:100px">
	                                            <div style="color:#9EA0A5">Unit #</div>
	                                            <div>{{ unit.unitNumber }}</div>
	                                        </div>
	                                    </div>
	                                    <div class="pr-3" style="min-width:120px; width:160px">
	                                        <div style="color:#9EA0A5">Marketing Name</div>
	                                        <div>{{ unit.name }}</div>
	                                    </div>
	                                    <div class="pr-3" style="min-width:100px; width:160px">
	                                        <div style="color:#9EA0A5">Interior</div>
	                                        <div>{{ unit.packages[0].sqft && unit.packages[0].sqft.toLocaleString() }} Sq.Ft.</div>
	                                    </div>
	                                    <div class="pr-3" style="min-width:100px; width:160px">
	                                        <div style="color:#9EA0A5">Exterior</div>
	                                        <div>{{ unit.packages[0].extSqft && unit.packages[0].extSqft.toLocaleString() }} Sq.Ft.</div>
	                                    </div>
	                                    <div class="pr-3" style="min-width:100px; width:160px">
	                                        <div style="color:#9EA0A5">Exposure</div>
	                                        <div>{{ unit.packages[0].exposure == 'north' ? 'N' : unit.packages[0].exposure == 'south' ? 'S' : unit.packages[0].exposure == 'east' ? 'E' : unit.packages[0].exposure == 'west' ? 'W' : unit.packages[0].exposure == 'northEast' ? 'N/E' : unit.packages[0].exposure == 'southEast' ? 'S/E' : unit.packages[0].exposure == 'southWest' ? 'S/W' : unit.packages[0].exposure == 'northwest' ? 'N/W' : 'N/A' }}</div>
	                                    </div>
	                                    <div class="pr-3" style="min-width:120px; width:180px">
	                                        <div style="color:#9EA0A5">Unit Premiums</div>
	                                        <template v-if="displayPremiums(unit).length">
	                                            <div class="dF aC">
	                                                <div v-for="(prem, premI) in displayPremiums(unit).slice(0, 1)" :key="prem + premI">{{ prem.name || 'No name' }}</div>
	                                                <a-tag class="ml-2" v-if="displayPremiums(unit).length > 1">{{ displayPremiums(unit).length - 1 }}+</a-tag>
	                                            </div>
	                                        </template>
	                                        <template v-else>
	                                            <div>No premiums</div>
	                                        </template>
	                                    </div>
	                                    <div class="pr-3" style="min-width:120px; width:180px">
	                                        <div style="color:#9EA0A5">Unit Add Ons</div>
	                                        <template v-if="displayAddons(unit).length">
	                                            <div class="dF aC">
	                                                <div v-for="(addon, addonI) in displayAddons(unit).slice(0, 1)" :key="addon + addonI">{{ addon.addonType }}</div>
	                                                <a-tag class="ml-2" v-if="displayAddons(unit).length > 1">{{ displayAddons(unit).length - 1 }}+</a-tag>
	                                            </div>
	                                        </template>
	                                        <template v-else>
	                                            <div>No addons</div>
	                                        </template>
	                                    </div>
	                                    <div class="pr-3" style="min-width:120px; width:180px">
	                                        <div style="color:#9EA0A5">Unit Premium Price</div>
	                                        <div>${{ premiumPrice(unit).toLocaleString() }}</div>
	                                    </div>
	                                    <div class="pr-3" style="min-width:120px; width:180px">
	                                        <div style="color:#9EA0A5">Unit Add On Price</div>
	                                        <div>${{ addonPrice(unit).toLocaleString() }}</div>
	                                    </div>
	                                    <div style="min-width:120px; width:180px">
	                                        <div style="color:#9EA0A5">Total Price</div>
	                                        <div>${{ (unit.packages[0].price + premiumPrice(unit) + addonPrice(unit)).toLocaleString() }}</div>
	                                    </div>
	                                </div>
									<div id="right" v-if="showScrollArrows" @click="scrollRight(unit.id)"><a-icon type="right" /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <div v-else>
                    <EmptyApp :text="'No units found'" :img="'https://bildhive.nyc3.digitaloceanspaces.com/v3/inventory_empty_d168efc25f.png'" />
                </div>
			</a-tab-pane>


			<!-- Premiums Tab -->
			<a-tab-pane key="2" tab="Premiums">
				<template v-if="Object.values(premiums).length">
                    <div class="mt-4 dF aC" style="justify-content:space-between">
                        <div class="dF aC">
                            <a-checkbox :indeterminate="indeterminatePremiums" :checked="checkAllPremiums"  @change="onchangeAllPremiums" class="mr-3" />
                            <h5 style="margin-bottom:0">Premiums</h5>
                            <div style="color:#9EA0A5" class="ml-3">{{`${Object.values(premiums).length} in total`}}</div>
                        </div>
                        <div class="dF aC">
                            <div @click="$p >= 40 ? $store.commit('OPEN_HIGHRISE_BUMP', {type:'premium',ids:selectedPremiums, cb:onBump}) : $message.error('You do not have permission to adjust the price')" v-if="selectedPremiums.length != 0" class="dF aC px-3 py-1" style="border-radius:3px; background-color:#F79425; cursor:pointer; color:#FFF; height:33.72px">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>
                                <div class="ml-3">Adjust Price</div>
                            </div>
                        </div>
                    </div>
                    <div class="dF mt-4" style="gap: 2rem; flex-direction: column;">
                        <div v-for="(prem,premI) in Object.values(premiums)" :key="prem+premI">
                            <div class="display-card py-3 px-3">
                                <div class="dF" style="justify-content:space-between; flex-wrap: nowrap;">
                                    <div class="dF">
                                        <a-checkbox class="pr-3 pt-1" @change="onChangePremium(prem.id)" :checked="selectedPremiums.includes(prem.id)" />
                                        <div class="pr-3" style="min-width:100px; width:150px">
                                            <div class="mb-2" style="color:#9EA0A5">Unit Premium</div>
                                            <div>{{prem.name || 'No name'}}</div>
                                        </div>
                                    </div>
                                    <div class="px-4 w-full" style="flex: 1;">
                                        <div class="mb-2" style="color:#9EA0A5">Available Unit(s)</div>
                                        <div v-if="prem.units.length" class="dF" style="gap: 1.5em; flex-wrap: wrap;">
                                            <div v-for="(unit,unitI) in prem.units" :key="unit+unitI" style="text-decoration: underline; display: inline-block;">{{'Unit ' + storedUnits[unit].unitNumber}}</div>
                                        </div>
                                        <div v-else>
                                            No units
                                        </div>
                                    </div>
                                    <div style="min-width:80px; width:100px">
                                        <div style="color:#9EA0A5">Price</div>
                                        <div>${{prem.value && prem.value.toLocaleString()}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <div v-else>
                    <EmptyApp :text="'No premiums found'" :img="'https://bildhive.nyc3.digitaloceanspaces.com/v3/inventory_empty_d168efc25f.png'" />
                </div>
			</a-tab-pane>


			<!-- Add Ons Tab -->
			<a-tab-pane key="3" tab="Add Ons">
				<div class="dF aC">
                    <a-button :type="addonTab == 'parking' ? 'primary' : ''" @click="addonTab = 'parking'">Parking Spots</a-button>
                    <a-button class="ml-4" :type="addonTab == 'lockers' ? 'primary' : ''" @click="addonTab = 'lockers'">Lockers</a-button>
                    <a-button class="ml-4" :type="addonTab == 'bikeRacks' ? 'primary' : ''" @click="addonTab = 'bikeRacks'">Bike Racks</a-button>
                </div>

				<!-- Parking Tab -->
                <div v-show="addonTab == 'parking'">
                    <template v-if="parkingAddOns && parkingAddOns.length">
                        <div class="mt-4 dF aC" style="justify-content:space-between">
                            <div class="dF aC">
                                <a-checkbox :indeterminate="indeterminateParking" :checked="checkAllParking"  @change="onchangeAllParking" class="mr-3" />
                                <h5 style="margin-bottom:0">Parking Spots</h5>
                                <div style="color:#9EA0A5" class="ml-3">{{`${parkingAddOns.length} in total`}}</div>
                            </div>
                            <div class="dF aC">
                                <div @click="$p >= 40 ? $store.commit('OPEN_HIGHRISE_BUMP', {type:'parking',ids:selectedParking, cb:onBump}) : $message.error('You do not have permission to adjust the price')" v-if="selectedParking.length != 0" class="dF aC px-3 py-1" style="border-radius:3px; background-color:#F79425; cursor:pointer; color:#FFF; height:33.72px">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>
                                    <div class="ml-3">Adjust Price</div>
                                </div>
                            </div>
                        </div>
                        <div class="dF mt-4" style="gap: 2rem; flex-direction: column;">
                            <div v-for="(addon,addonI) in parkingAddOns" :key="addon+addonI">
                                <div class="display-card py-3 px-3">
                                    <div class="dF" style="justify-content:space-between">
                                        <div class="dF">
                                            <a-checkbox class="pr-3 pt-1" @change="onChangeParking(addon.id)" :checked="selectedParking.includes(addon.id)" />
                                            <div class="pr-3" style="min-width:80px; width:80px">
                                                <div style="color:#9EA0A5">Parking ID</div>
                                                <div>{{addon.pID}}</div>
                                            </div>
                                        </div>
                                        <div class="pr-3" style="min-width:100px; width:160px">
                                            <div style="color:#9EA0A5">Parking Spot #</div>
                                            <div>{{addon.name}}</div>
                                        </div>
                                        <div class="pr-3" style="min-width:100px; width:160px">
                                            <div style="color:#9EA0A5">Parking Spot Type</div>
                                            <div>{{addon.type}}</div>
                                        </div>
                                        <div class="pr-3" style="min-width:100px; width:160px">
                                            <div style="color:#9EA0A5">Parking Spot Description</div>
                                            <div>{{addon.description}}</div>
                                        </div>
                                        <div class="pr-3" style="min-width:80px; width:120px">
                                            <div style="color:#9EA0A5">Unit #</div>
                                            <div>{{(addon.unitId && storedUnits[addon.unitId] && storedUnits[addon.unitId].unitNumber) || 'No Unit'}}</div>
                                        </div>
                                        <div style="min-width:60px; width:60px">
                                            <div style="color:#9EA0A5">Price</div>
                                            <div>${{addon.price && addon.price.toLocaleString()}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <div v-else>
                        <EmptyApp :text="'No parking spots found'" :img="'https://bildhive.nyc3.digitaloceanspaces.com/v3/inventory_empty_d168efc25f.png'" />
                    </div>
                </div>


				<!-- Lockers Tab -->
                <div v-show="addonTab == 'lockers'">
                    <template v-if="lockersAddOns && lockersAddOns.length">
                        <div class="mt-4 dF aC" style="justify-content:space-between">
                            <div class="dF aC">
                                <a-checkbox :indeterminate="indeterminateLockers" :checked="checkAllLockers"  @change="onchangeAllLockers" class="mr-3" />
                                <h5 style="margin-bottom:0">Lockers</h5>
                                <div style="color:#9EA0A5" class="ml-3">{{`${lockersAddOns.length} in total`}}</div>
                            </div>
                            <div class="dF aC">
                                <div @click="$p >= 40 ? $store.commit('OPEN_HIGHRISE_BUMP', {type:'lockers',ids:selectedLockers, cb:onBump}) : $message.error('You do not have permission to adjust the price')" v-if="selectedLockers.length != 0" class="dF aC px-3 py-1" style="border-radius:3px; background-color:#F79425; cursor:pointer; color:#FFF; height:33.72px">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>
                                    <div class="ml-3">Adjust Price</div>
                                </div>
                            </div>
                        </div>
                        <div class="dF mt-4" style="gap: 2rem; flex-direction: column;">
                            <div v-for="(addon,addonI) in lockersAddOns" :key="addon+addonI">
                                <div class="display-card py-3 px-3">
                                    <div class="dF" style="justify-content:space-between">
                                        <div class="dF">
                                            <a-checkbox class="pr-3 pt-1" @change="onChangeLockers(addon.id)" :checked="selectedLockers.includes(addon.id)" />
                                            <div class="pr-3" style="min-width:80px; width:100px">
                                                <div style="color:#9EA0A5">Locker ID</div>
                                                <div>{{addon.lID}}</div>
                                            </div>
                                        </div>
                                        <div class="pr-3" style="min-width:100px; width:120px">
                                            <div style="color:#9EA0A5">Locker #</div>
                                            <div>{{addon.name}}</div>
                                        </div>
                                        <div class="pr-3" style="min-width:100px; width:160px">
                                            <div style="color:#9EA0A5">Locker Description</div>
                                            <div>{{addon.description}}</div>
                                        </div>
                                        <div class="pr-3" style="min-width:80px; width:100px">
                                            <div style="color:#9EA0A5">Unit #</div>
                                            <div>{{(addon.unitId && storedUnits[addon.unitId] && storedUnits[addon.unitId].unitNumber) || 'No Unit'}}</div>
                                        </div>
                                        <div style="min-width:80px; width:100px">
                                            <div style="color:#9EA0A5">Price</div>
                                            <div>${{addon.price && addon.price.toLocaleString()}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <div v-else>
                        <EmptyApp :text="'No lockers found'" :img="'https://bildhive.nyc3.digitaloceanspaces.com/v3/inventory_empty_d168efc25f.png'" />
                    </div>
                </div>


				 <!-- Bike Racks Tab -->
                <div v-show="addonTab == 'bikeRacks'">
                    <template v-if="bikeRacksAddOns && bikeRacksAddOns.length">
                        <div class="mt-4 dF aC" style="justify-content:space-between">
                            <div class="dF aC">
                                <a-checkbox :indeterminate="indeterminateBikeRacks" :checked="checkAllBikeRacks"  @change="onchangeAllBikeRacks" class="mr-3" />
                                <h5 style="margin-bottom:0">Bike Racks</h5>
                                <div style="color:#9EA0A5" class="ml-3">{{`${bikeRacksAddOns.length} in total`}}</div>
                            </div>
                            <div class="dF aC">
                                <div @click="$p >= 40 ? $store.commit('OPEN_HIGHRISE_BUMP', {type:'bikeracks',ids:selectedBikeRacks, cb:onBump}) : $message.error('You do not have permission to adjust the price')" v-if="selectedBikeRacks.length != 0" class="dF aC px-3 py-1" style="border-radius:3px; background-color:#F79425; cursor:pointer; color:#FFF; height:33.72px">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>
                                    <div class="ml-3">Adjust Price</div>
                                </div>
                            </div>
                        </div>
                        <div class="dF mt-4" style="gap: 2rem; flex-direction: column;">
                            <div v-for="(addon,addonI) in bikeRacksAddOns" :key="addon+addonI">
                                <div class="display-card py-3 px-3">
                                    <div class="dF aC" style="justify-content:space-between">
                                        <div class="dF aC">
                                            <a-checkbox class="pr-3" @change="onChangeBikeRacks(addon.id)" :checked="selectedBikeRacks.includes(addon.id)" />
                                            <div class="pr-3" style="min-width:80px; width:100px">
                                                <div style="color:#9EA0A5">Bike Rack ID</div>
                                                <div>{{addon.bID}}</div>
                                            </div>
                                        </div>
                                        <div class="pr-3" style="min-width:100px; width:120px">
                                            <div style="color:#9EA0A5">Bike Rack #</div>
                                            <div>{{addon.name}}</div>
                                        </div>
                                        <div class="pr-3" style="min-width:100px; width:160px">
                                            <div style="color:#9EA0A5">Bike Rack Description</div>
                                            <div>{{addon.description}}</div>
                                        </div>
                                        <div class="pr-3" style="min-width:80px; width:100px">
                                            <div style="color:#9EA0A5">Unit #</div>
                                            <div>{{(addon.unitId && storedUnits[addon.unitId] && storedUnits[addon.unitId].unitNumber) || 'No Unit'}}</div>
                                        </div>
                                        <div style="min-width:80px; width:100px">
                                            <div style="color:#9EA0A5">Price</div>
                                            <div>${{addon.price && addon.price.toLocaleString()}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <div v-else>
                        <EmptyApp :text="'No bike racks found'" :img="'https://bildhive.nyc3.digitaloceanspaces.com/v3/inventory_empty_d168efc25f.png'" />
                    </div>
                </div>
			</a-tab-pane>
		</a-tabs>

    </div>
</template>

<script>

import EmptyApp from 'bh-mod/components/common/EmptyApp'

export default {
    components:{EmptyApp},
    watch:{
        '$route':{
            immediate:true,
            handler(val) {
                if (val.params && val.params.id && val.params.id != '') {
                    this.$store.commit('SET_PROP', {where:['selectedFloor'],what:val.params.id})
                }
            }
        }
    },
	computed:{
        unitGroups() {
            let uG= {}
            Object.values(this.$store.state.inventory.unitGroups).forEach(group => {
                if (group.buildingType == 'condounits') {
                    uG[group.id] = group
                }
            })
            return uG
        },
        floor(){
            return this.$store.state.inventory.selectedFloor
        },
        units() {
            let units = {}
            let filter = this.$store.state.inventory.highriseFilter
            if (Object.keys(this.$store.state.inventory.highriseArray).length == 0 && filter.price.length == 0 && filter.beds[0] == 'any' && filter.baths == 'any' && filter.exposure[0] == 'any' && filter.status[0] == 'any') {
                if (this.floor && this.floor != '') {
                    Object.values(this.storedUnits).forEach(unit => {
                        if (unit.buildingType == 'condounits' && unit.unitGroup.id == this.floor) {
                            units[unit.id] = unit
                        }
                    })
                }
            } else {
                if (this.floor && this.floor != '') {
                    Object.values(this.$store.state.inventory.highriseArray).forEach(unit => {
                        if (unit.buildingType == 'condounits' && unit.unitGroup.id == this.floor) {
                            units[unit.id] = unit
                        }
                    })
                }
            }
            return units
        },
        instance() {
            return this.$store.state.instance
        },
        storedUnits() {
            let units = {}
            const orderAlpha = (array) => {
                array = array.sort((a,b) => {
                    let aa = parseInt(a.unitNumber) || a.unitNumber;
					let bb = parseInt(b.unitNumber) || b.unitNumber;
                    return aa < bb ? -1 : aa > bb ? 1 : 0;
                })
                return array
            }
            if (this.$store.state.instance.productType == 'highrise') {
                orderAlpha(Object.values(this.$store.state.inventory.units)).forEach(unit => {
                    if(unit.buildingType == 'condounits') {
                        units[unit.id] = unit
                    }
                })
            }
            return units
        },
        condoPremiums(){
            return this.$store.state.inventory.condoPremiums
        },
        condoAddons(){
            return this.$store.state.inventory.condoAddons
        },
		parkingAddOns() {
			const parking = this.condoAddons && this.condoAddons.parking || [];

			return parking.sort((a, b) => {
				let aa = parseInt(a.name && a.name.toString().replace(/\D+/g, '') || 0) || 0;
				let bb = parseInt(b.name && b.name.toString().replace(/\D+/g, '') || 0) || 0;
				return aa - bb;
			})
		},

		lockersAddOns() {
			const lockers = this.condoAddons && this.condoAddons.lockers || [];

			return lockers.sort((a, b) => {
				let aa = parseInt(a.name && a.name.toString().replace(/\D+/g, '') || 0) || 0;
				let bb = parseInt(b.name && b.name.toString().replace(/\D+/g, '') || 0) || 0;
				return aa - bb;
			})
		},

		bikeRacksAddOns() {
			const bikeRacks = this.condoAddons && this.condoAddons.bikeRacks || [];

			return bikeRacks.sort((a, b) => {
				let aa = parseInt(a.name && a.name.toString().replace(/\D+/g, '') || 0) || 0;
				let bb = parseInt(b.name && b.name.toString().replace(/\D+/g, '') || 0) || 0;
				return aa - bb;
			})
		},
        premiums(){
            let units = Object.values(this.storedUnits)
            let premiums = JSON.parse(JSON.stringify(this.condoPremiums))

            units.forEach(x => {
				if(x.packages[0].other && x.packages[0].other.premiums) {
					for (var y in x.packages[0].other.premiums){
						let y2 = x.packages[0].other.premiums[y]
						if (premiums.hasOwnProperty(y)){
							if (y2.active){
								premiums[y].units.push(x.id)
							}
						}
					}
				}
            })
            return premiums
        }
    },

    data() {
        return {
            selectedUnits:[],
            indeterminateUnits:false,
            checkAllUnits:false,
            selectedPremiums:[],
            indeterminatePremiums:false,
            checkAllPremiums:false,
            addonTab:'parking',
            selectedParking:[],
            indeterminateParking:false,
            checkAllParking:false,
            selectedLockers:[],
            indeterminateLockers:false,
            checkAllLockers:false,
            selectedBikeRacks:[],
            indeterminateBikeRacks:false,
            checkAllBikeRacks:false,
			showScrollArrows: false,
			resizeObserver: null
        }
    },

	methods:{
        onBump(){
            this.selectedUnits = []
            this.selectedPremiums = []
            this.selectedParking = []
            this.selectedLockers = []
            this.selectedBikeRacks = []
        },
        addonPrice(unit){
            let addons = []
            let price = 0
            if (unit.packages[0].other && unit.packages[0].other.addons && unit.packages[0].other.addons.hasOwnProperty('parking')){
                unit.packages[0].other.addons.parking.forEach(x => {
                    x.addonType = 'Parking Spot'
                    addons.push(x)
                })
                unit.packages[0].other.addons.lockers.forEach(x => {
                    x.addonType = 'Locker'
                    addons.push(x)
                })
                unit.packages[0].other.addons.bikeRacks.forEach(x => {
                    x.addonType = 'Bike Rack'
                    addons.push(x)
                })
                addons.forEach(addon => {
                    price += parseInt(addon.price)
                })
            }
            return price
        },
        premiumPrice(unit){
            let price = 0
			if(unit.packages[0] && unit.packages[0].other && unit.packages[0].other.premiums) {
				Object.values(unit.packages[0].other.premiums).forEach(x => {
					if (x.active && x.value){
						price += parseInt(x.value)
					}
				})
			}
            return price
        },
        displayPremiums(unit){
            let premiums = []
			if(unit.packages[0] && unit.packages[0].other && unit.packages[0].other.premiums) {
				Object.values(unit.packages[0].other.premiums).forEach(x => {
					if (x.active){
						premiums.push(x)
					}
				})
			}
            return premiums
        },
        displayAddons(unit){
            let addons = []
            if (unit.packages[0].other && unit.packages[0].other.addons && unit.packages[0].other.addons.hasOwnProperty('parking')){
                unit.packages[0].other.addons.parking.forEach(x => {
                    x.addonType = 'Parking Spot'
                    addons.push(x)
                })
                unit.packages[0].other.addons.lockers.forEach(x => {
                    x.addonType = 'Locker'
                    addons.push(x)
                })
                unit.packages[0].other.addons.bikeRacks.forEach(x => {
                    x.addonType = 'Bike Rack'
                    addons.push(x)
                })
            }
            return addons
        },
        backButton() {
            this.$router.push('/')
        },
        onChangeUnit (id) {
            if (!this.selectedUnits.includes(id)) {
                this.selectedUnits.push(id)
            } else {
                let index = this.selectedUnits.findIndex(x => x == id)
                this.selectedUnits.splice(index,1)
            }
            if (this.selectedUnits.length == 0) {
                this.indeterminateUnits = false
                this.checkAllUnits = false
            }
            if (this.selectedUnits.length < Object.values(this.storedUnits).length && this.selectedUnits.length != 0) this.indeterminateUnits = true
            if  (this.selectedUnits.length == Object.values(this.storedUnits).length) {
                this.indeterminateUnits = false
                this.checkAllUnits = true
            }
        },
        onchangeAllUnits(e) {
            if (e.target.checked) {
                this.indeterminateUnits = false
                this.checkAllUnits = true
                Object.values(this.storedUnits).forEach(unit => {
                    this.selectedUnits.push(unit.id)
                })
            } else {
                this.checkAllUnits = false
                this.indeterminateUnits = false
                this.selectedUnits = []
            }
        },
        onChangePremium(id) {
            if (!this.selectedPremiums.includes(id)) {
                this.selectedPremiums.push(id)
            } else {
                let index = this.selectedPremiums.findIndex(x => x == id)
                this.selectedPremiums.splice(index,1)
            }
            if (this.selectedPremiums.length == 0) {
                this.indeterminatePremiums = false
                this.checkAllPremiums = false
            }
            if (this.selectedPremiums.length < Object.values(this.premiums).length && this.selectedPremiums.length != 0) this.indeterminatePremiums = true
            if  (this.selectedPremiums.length == Object.values(this.premiums).length) {
                this.indeterminatePremiums = false
                this.checkAllPremiums = true
            }
        },
        onchangeAllPremiums(e) {
            if (e.target.checked) {
                this.indeterminatePremiums = false
                this.checkAllPremiums = true
                Object.values(this.premiums).forEach(prem => {
                    this.selectedPremiums.push(prem.id)
                })
            } else {
                this.checkAllPremiums = false
                this.indeterminatePremiums = false
                this.selectedPremiums = []
            }
        },
        onChangeParking(id) {
            if (!this.selectedParking.includes(id)) {
                this.selectedParking.push(id)
            } else {
                let index = this.selectedParking.findIndex(x => x == id)
                this.selectedParking.splice(index,1)
            }
            if (this.selectedParking.length == 0) {
                this.indeterminateParking = false
                this.checkAllParking = false
            }
            if (this.selectedParking.length < this.parkingAddOns.length && this.selectedParking.length != 0) this.indeterminateParking = true
            if  (this.selectedParking.length == this.parkingAddOns.length) {
                this.indeterminateParking = false
                this.checkAllParking = true
            }
        },
        onchangeAllParking(e) {
            if (e.target.checked) {
                this.indeterminateParking = false
                this.checkAllParking = true
                this.parkingAddOns.forEach(parking => {
                    this.selectedParking.push(parking.id)
                })
            } else {
                this.checkAllParking = false
                this.indeterminateParking = false
                this.selectedParking = []
            }
        },
        onChangeLockers(id) {
            if (!this.selectedLockers.includes(id)) {
                this.selectedLockers.push(id)
            } else {
                let index = this.selectedLockers.findIndex(x => x == id)
                this.selectedLockers.splice(index,1)
            }
            if (this.selectedLockers.length == 0) {
                this.indeterminateLockers = false
                this.checkAllLockers = false
            }
            if (this.selectedLockers.length < this.lockersAddOns.length && this.selectedLockers.length != 0) this.indeterminateLockers = true
            if  (this.selectedLockers.length == this.lockersAddOns.length) {
                this.indeterminateLockers = false
                this.checkAllLockers = true
            }
        },
        onchangeAllLockers(e) {
            if (e.target.checked) {
                this.indeterminateLockers = false
                this.checkAllLockers = true
                this.lockersAddOns.forEach(locker => {
                    this.selectedLockers.push(locker.id)
                })
            } else {
                this.checkAllLockers = false
                this.indeterminateLockers = false
                this.selectedLockers = []
            }
        },
        onChangeBikeRacks(id) {
            if (!this.selectedBikeRacks.includes(id)) {
                this.selectedBikeRacks.push(id)
            } else {
                let index = this.selectedBikeRacks.findIndex(x => x == id)
                this.selectedBikeRacks.splice(index,1)
            }
            if (this.selectedBikeRacks.length == 0) {
                this.indeterminateBikeRacks = false
                this.checkAllBikeRacks = false
            }
            if (this.selectedBikeRacks.length < this.bikeRacksAddOns.length && this.selectedBikeRacks.length != 0) this.indeterminateBikeRacks = true
            if  (this.selectedBikeRacks.length == this.bikeRacksAddOns.length) {
                this.indeterminateBikeRacks = false
                this.checkAllBikeRacks = true
            }
        },
        onchangeAllBikeRacks(e) {
            if (e.target.checked) {
                this.indeterminateBikeRacks = false
                this.checkAllBikeRacks = true
                this.bikeRacksAddOns.forEach(bikeRack => {
                    this.selectedBikeRacks.push(bikeRack.id)
                })
            } else {
                this.checkAllBikeRacks = false
                this.indeterminateBikeRacks = false
                this.selectedBikeRacks = []
            }
        },

		updateScrollVisibility(){
			let scrollContent = document.querySelector('.price-div');

			if(scrollContent) {
				if(scrollContent.offsetWidth < scrollContent.scrollWidth){
					this.showScrollArrows = true;
				} else {
					this.showScrollArrows = false;
				}
			}
		},

		scrollLeft(id) {
			const scrollableArea = document.getElementById(id);
			const scrollSize = Math.floor(scrollableArea.clientWidth - (scrollableArea.clientWidth * 0.1))
			scrollableArea.scrollBy({
				left: -scrollSize,
				behavior: "smooth",
			});
		},

		scrollRight(id) {
			const scrollableArea = document.getElementById(id);
			const scrollSize = Math.floor(scrollableArea.clientWidth - (scrollableArea.clientWidth * 0.1))
			scrollableArea.scrollBy({
				left: scrollSize,
				behavior: "smooth",
			});
		},
    },

	mounted() {
		let scrollContent = document.querySelector('.price-div');
		this.resizeObserver = new ResizeObserver(() => {
			window.requestAnimationFrame(() => {
				this.updateScrollVisibility()
			})
		});
		if (scrollContent) {
			this.resizeObserver.observe(scrollContent);
		}

		// Remove the previous event listener, if any, to prevent multiple bindings
		window.removeEventListener('resize', this.updateScrollVisibility);

		// Attach the updated event listener
		window.addEventListener('resize', this.updateScrollVisibility)
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.updateScrollVisibility)
		if (this.resizeObserver) {
			this.resizeObserver.disconnect();
        }
    },
}
</script>

<style>

</style>
